<template>
  <div class="">
    <div class="px-4 py-2 border border-solid border-gray-500 rounded-lg">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <div
            class="bg-success p-10 text-center text-3xl text-uppercase text-white rounded-lg line-height-27 my-2"
          >
            7 Days Study Plan
          </div> 
        </div>
        <div class="md-layout-item">
          <div
            class="bg-default p-10 text-center text-3xl text-uppercase text-white rounded-lg line-height-27 my-2"
          >
            15 Days Study Plan
          </div>
        </div>
        <div class="md-layout-item">
          <div
            class="bg-danger p-10 text-center text-3xl text-uppercase text-white rounded-lg line-height-27 my-2"
          >
            1 Month Study Plan
          </div>
        </div>
      </div>
    </div>

    <div class="mt-16  p-4 border border-solid border-gray-500 rounded-lg">
      <div class="text-victoria text-uppercase text-xl font-bold">
        Your study plan status
      </div>

      <div class="md-layout md-gutter my-6">
        <div class="md-layout-item">
          <div class="flex">
            <span
              class="bg-tahiti-gold p-2 mr-4 text-white font-bold rounded-b-full text-2xl"
              >15</span
            >
            <div>
              <h3>15 Days Study Plan</h3>
              <h3 class="text-italic opacity-50">10 Days remaining</h3>
            </div>
          </div>
        </div>
        <div class="md-layout-item">
          <div class="flex justify-end">
            <md-button class="bg-alto ">15% Complete</md-button>
            <md-button class="bg-tahiti-gold text-white"
              >Download Plan</md-button
            >
            <div
              class="inline-block rounded text-white pointer"
              style="padding: 5px; margin: 6px 0; border: 1px solid #ef7206"
              @click="switchModal('STStudyPlanList')"
            >
              <md-icon class="m-0" style="color: #ef7206">visibility</md-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="mt-16 border-t border-solid border-gray-500"></div>

      <div class=" text-center my-16">
        <h3 class="text-uppercase text-2xl mb-6">
          Do You Need Custom Study Plan ?
        </h3>
        <div
          @click="switchModal('STStudyPlanRequest')"
          class="bg-victoria text-4xl text-uppercase p-10 pointer text-white rounded-lg inline-block"
        >
          Make A request for Study plan
        </div>
      </div>
    </div>
    <!-- Dialog Start -->
    <Dialog>
       <component :is="dialogComponent"></component>
    </Dialog>
    <!-- Dialog Start -->
  </div>
</template>

<script>
import Dialog from "@/components/atom/Dialog";
import STStudyPlanRequest from "@/components/molecule/study-plan/STStudyPlanRequest";
import STStudyPlanList from "@/components/molecule/study-plan/STStudyPlanList";
import { mapMutations } from "vuex";
export default {
  components: {
    Dialog,
    STStudyPlanRequest,
    STStudyPlanList
  },
  data(){
    return {
      dialogComponent: 'STStudyPlanRequest'
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    switchModal(value) {
      this.dialogComponent = value;
      this.dialog(true);
    }
  },
};
</script>

<style></style>
