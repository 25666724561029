<template>
  <div class="p-6">
    <div
      class="bg-success p-4 text-lg text-uppercase rounded flex justify-between text-white"
    >
      <p>Plan Name: Importance In Listening</p>
      <span class="font-bold">7 Days Study Plan</span>
    </div>
    <div>
      <Table :items="stStudyPlanList">
        <template slot="row" slot-scope="{ item }" md-selectable="single">
          <md-table-cell md-label="#" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="DAY">{{ item.day }}</md-table-cell>
          <md-table-cell md-label="TASK NAME">{{ item.taskName }}</md-table-cell>
          <md-table-cell md-label="RELATED MATERIAL" class="text-victoria">{{ item.relatedMaterial }}</md-table-cell>
          <md-table-cell md-label="HOMEWORK">{{ item.homework }}</md-table-cell>
          <md-table-cell md-label="DETAILS">{{ item.details }}</md-table-cell>
          <md-table-cell md-label="STATUS">
            <span class="bg-success text-white px-1 rounded">{{ item.status }}</span>
          </md-table-cell>
          
        </template>
      </Table>
    </div>
    <div class="flex justify-end mt-6">
        <md-button class="bg-success p-2 rounded text-white">Download</md-button>
    </div>
  </div>
</template>

<script>
import { Table } from "@/components";
import data from "@/data/study-plan/data"
export default {
  components: {
    Table
  },
  data() {
    return {
      stStudyPlanList: data.stStudyPlanList
    }
  }
};
</script>

<style></style>
