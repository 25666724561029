<template>
  <div class="p-6" style="width: 850px;">
    <DialogTitle bg-color="bg-primary" label="Study Plan Request" />
    <div class="mt-6">
      <label class="text-uppercase font-bold mb-2 mr-3 inline-block"
        >Your Full Name</label
      >
      <SelectComponent class="w-64" />
    </div>

    <div class="mt-6">
      <label class="text-uppercase font-bold mb-2 mr-3 inline-block"
        >Name Fo Exam Center</label
      >
      <SelectComponent class="w-64" />
    </div>

    <div class="w-75p mt-6">
      <div class="w-50p inline-block pr-2">
        <InputFieldComponent label="Exam Date" />
      </div>
      <div class="w-50p inline-block pl-2">
        <InputFieldComponent label="Exam Time" />
      </div>
    </div>

    <div class="w-75p mt-6">
      <div class="w-50p inline-block pr-2">
        <label class="text-uppercase font-bold mb-2 inline-block"
          >Do You Have Given any Exam Before</label
        >
        <SelectComponent class="w-64" />
      </div>
      <div class="w-50p inline-block pl-2">
        <label class="text-uppercase font-bold mb-2 inline-block"
          >How Many Exam You Have Taken</label
        >
        <SelectComponent class="w-64" />
      </div>
    </div>

    <div class="w-75p mt-6">
      <div class="flex align-center">
        <label class="text-uppercase font-bold mb-2 inline-block"
          >1. Upload your exam result</label
        >
        <md-button class="bg-primary text-white rounded text-uppercase"
          >Upload</md-button
        >
      </div>
      <div class="flex align-center">
        <label class="text-uppercase font-bold mb-2 inline-block"
          >2. Upload your exam result</label
        >
        <md-button class="bg-primary text-white rounded text-uppercase"
          >Upload</md-button
        >
      </div>
    </div>
    <div class="flex justify-center mt-6">
      <div
        class="bg-primary text-center w-75p text-3xl text-uppercase p-8 pointer text-white rounded-lg"
      >
        Submit Request
      </div>
    </div>
  </div>
</template>

<script>
import {
  DialogTitle,
  SelectComponent,
  InputFieldComponent
  } from "@/components";

export default {
  components: {
    DialogTitle,
    SelectComponent,
    InputFieldComponent,
  },
};
</script>