
const planAchieves = [];

for (let i = 0; i < 15; i++) {
    
    planAchieves.push({
        id: i,
        planId: 'TAPI'+i,
        planName: '7 days plan',
        duration: '7 days',
        createdBy: 'Kazi Shahin',
        branch: 'Dhanmondi',
        course: 'EXP. Listening',
        status: 'Live'
    });
}

const planList = [];

for (let i = 0; i < 15; i++) {
    
    planList.push({
        id: i,
        studentId: 'TAPI12'+i,
        studentName: 'Faysal Ahmed',
        meetingId: 'MEET200'+i,
        duration: '30 days',
        createdBy: 'Kazi Shahin',
        branch: 'Dhanmondi',
        course: 'EXP. Listening',
        status: 'On Going'
    });
}

const stStudyPlanList = [];
for (let j = 0; j < 15; j++) {
    stStudyPlanList.push({
        id: j,
        day: 'Day 1',
        taskName: 'Reading chapter 1',
        relatedMaterial: 'IMP.LISTENING',
        homework: 'Complete chapter 1',
        details: 'Complete all the tanks from ...',
        status: 'Completed'
    });
    
}

export default {
    planAchieves,
    planList,
    stStudyPlanList
}